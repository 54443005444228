.dashboardHeader {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
}

.surveyHeading {
    font-weight: 600;
}

.surveysDueHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.surveysDueHeader h2 {
    font-weight: 600;
}

.newSurveyButton {
    background-color: #d3a045;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1.5rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}

.previousSurveyButton {
    background-color: #333;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}

.surveysDueSearchButton {
    border: none;
    background: #d3a045;
    padding: 0.25rem 0.5rem;
    color: white;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.surveysDueSearchInput {
    border: none;
    padding: 0.5rem;
    margin-right: 2rem;
    outline: none;
    border-radius: 4px;
}

.surveyRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 1rem 2rem;
    margin-top: 0.5rem;
}

.surveyRow div {
    font-weight: 500;
}

.recentSurveyRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    margin-top: 0.5rem;
}

.recentSurveyRow div {
    font-weight: 500;
}

.editRowButton {
    background-color: #d3a045;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.editRowButton svg {
    margin-left: 0.5rem;
}

.newSurveyButton {
    background-color: #d3a045;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1.5rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}

.previousSurveyButton {
    background-color: #333;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}

.viewMoreContainer {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
}

.viewMoreButton {
    background-color: #333333;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}

.hotelName {
    width: 40%;
}

.surveyType {
    width: 25%;
}

.surveyStatus {
    width: 15%;
}

.actions {
    width: 10%;
}

.surveysNotFound {
    font-weight: 500;
}