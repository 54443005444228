.editRepairContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.5rem;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 0.75rem;
}

.editRepairContainer:first-of-type {
    margin-top: 0;
}

.ref {
    font-weight: 600;
    width: 15%;
}

.message {
    font-weight: 500;
    width: 65%;
}

.tickContainer {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit {
    width: 10%;
}

.editButton {
    background-color: #d3a045;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    color: #fff;
}

.editRepairFormContainer {
    width: 90%;
    margin-left: auto;
}

.editRepair {
    background-color: #d3a045;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: .5rem 1.25rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}

.urgentContainer {
    background-color: #F04031;
    color: #fff;
}

.urgent {
    font-weight: 600;
}

.tick {
    padding: 0.25rem;
    background: #4BC33E;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    color: #fff;
}