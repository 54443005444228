.completedContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5rem;
}

.formContainer {
    padding: 1.5rem 1rem;
}

.formTextarea {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 0.5rem;
}

.saveButton {
    background-color: #d3a045;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.completedDate {
    margin-left: 1rem;
    outline: none;
    border: 0;
    height: 30px;
}

.completedDateLabel {
    font-size: 0.875rem;
    font-weight: 800;
}