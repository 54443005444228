.repairsContainer {
    margin-top: 1.5rem;
}

.previousSurveyButton {
    background-color: #333;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}