.background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.26);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
}

.sectionButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.sectionHeading {
    text-align: center;
    margin: 0;
}

.sectionContainer {
    margin-top: 4rem;
    background: #f4f4f4;
    width: 300px;
    padding: 2rem 1rem 2rem;
}

.buttonIcon {
    font-size: 1.5rem;
    color: #fff;
}

.nextDoorButton,
.saveOtherButton {
    color: #fff;
    background-color: #D3A045;
    font-size: 1.125rem;
    text-transform: uppercase;
    border-radius: 8px;
    border: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.saveOtherButton {
    width: 100px;
}

.tickButton {
    border: none;
    background-color: #4BC33E;
    border-radius: 20px;
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    width: 64px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.crossButton {
    border: none;
    background-color: #CD2121;
    border-radius: 20px;
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    width: 64px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.naButton {
    border: none;
    background-color: #272727;
    border-radius: 20px;
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    width: 64px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.25rem;
}

.failedOptionButton {
    border: none;
    background-color: #999;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    width: 110px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
}

.failedOptionButton.activeOption {
    background-color: #CD2121;
}

.failedOther {
    width: 100%;
    border-radius: 3px;
    border: none;
    height: 70px;
}

.commentError {
    font-size: 0.75rem;
    color: #CD2121;
}

.failedOptionError {
    font-size: 0.75rem;
    color: #CD2121;
    text-align: center;
    margin-top: 0.5rem;
}

.urgentButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
}

.urgentButton {
    border: none;
    background-color: #999;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    width: 110px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
}

.urgentButtonActive {
    background-color: #cd2121;
}