.doorList p {
    text-align: center;
}

.doorList__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    margin-top: 1rem;
}

.doorList__container .small {
    color: #333;
    font-size: 0.75rem;
}

.doorList__container button {
    appearance: none;
    border: none;
    cursor: pointer;
    background-color: #d3a045;
    outline: none;
    padding: 0.25rem 0.5rem;
    color: #fff;
}

.doorList__container button.remove {
    background-color: #cc2020;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

.doorList__container button.edit {
    background-color: #d3a045;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

.doorList__content {
    display: flex;
    align-items: center;
    flex: 1 1 0%;
    justify-content: space-between;
    padding-right: 1rem;
}

.addDoor {
    padding: .5rem 1rem !important;
    text-align: center;
}

.door-error {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
}

.door-error svg {
    color: #cc2020;
}

.door-complete {
    width: 24px;
    height: 24px;
}

.door-complete svg {
    fill: rgba(110, 231, 183, 1);
}

.doorList__validationContainer {
    display: flex;
    align-items: center;
}

.processSurvey {
    padding: 0.5rem 1rem !important;
}

@media only screen and (max-width: 600px) {
    .form__buttons {
        flex-direction: column;
        justify-content: center !important;
    }
    
    .form__buttons button {
        margin-top: 1rem;
        display: block;
    }
}