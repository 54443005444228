.doorContainer {
    display: grid;
    grid-template-columns: minmax(0,2fr) minmax(0,2fr);
    margin-top: 0.5rem;
    gap: 1rem;
}

.fieldBlock {
    background-color: white;
    display: block;
    padding: 0.5rem 1rem;
}

.fieldBlock input,
.fieldBlock select {
    border: 0;
    outline: 0;
    width: 100%;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2rem;
}

.doorSelectButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.doorButtons {
    display: flex;
    margin-top: 1.5rem;
}

.doorButton {
    border: 5px solid #C5C5C5;
    color: #C5C5C5;
    background-color: #fff;
    padding: 8px 16px;
    border-radius: 50px;
    cursor: pointer;
    width: 48%;
    text-transform: uppercase;
    font-weight: 600;
}

.doorButton.active {
    color: #4BC33E;
    border-color: #4BC33E;
}

.otherInfoButton {
    color: #fff;
    background-color: #272727;
    font-size: 1.125rem;
    text-transform: uppercase;
    border-radius: 8px;
    border: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.nextDoorButton {
    color: #fff;
    background-color: #D3A045;
    font-size: 1.125rem;
    text-transform: uppercase;
    border-radius: 8px;
    border: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.otherInfoButton {
    background-color: #272727;
    color: #fff;
    font-size: 1.125rem;
    text-transform: uppercase;
    border-radius: 8px;
    border: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
}

.finishButton {
    color: #fff;
    background-color: #CD2121;
    font-size: 1.125rem;
    text-transform: uppercase;
    border-radius: 8px;
    border: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.imageContainer {
    position: relative;
}

.clickableTriangle {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.5rem;
}

.clickable {
    border: 5px solid white;
    border-radius: 9999px;
    width: 55px;
    height: 55px;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.5rem;
}

.startPosition {
    position: absolute;
    font-size: 0.95rem;
    top: 55px;
}

.iconStartPosition {
    position: absolute;
    top: 45px;
}

.activeMarker {
    border: 5px solid #40e0d0 !important;
}

.doorButtons {
    display: flex;
    flex-direction: column;
}

.doorButtonsBottom {
    margin-top: 2.5rem;
}

.photographContainer {
    display: flex;
    align-items: center;
}

.photograph {
    padding: 1rem;
    background-color: #fff;
    width: 100%;
    max-width: 240px;
}

.previewContainer {
    border: 1px solid #999;
    border-radius: 5px;
    margin-left: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 5px;
    position: relative;
}

.preview {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.photoIcon {
    width: 40px;
    height: auto;
    object-fit: contain;
}

.removePhoto {
    width: 18px;
    height: 18px;
    background-color: #CD2121;
    color: #fff;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -9px;
    right: -9px;
    padding: 5px;
    border: none;
    cursor: pointer;
}

.removeRightPhoto {
    background-color: #a11a1a;
}

.textArea {
    border: none;
    resize: none;
    width: 100%;
    height: 100px;
}

.doorViewContainer {
    display: flex;
}

.leftDoorNext {
    position: relative;
    right: 0;
    background-color: #f3f3f3;
}

.rightDoorNext {
    position: relative;
    left: 0;
    background-color: #f3f3f3;
}

.previewErrorContainer {
    margin-left: 0.5rem;
}

