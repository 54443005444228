.heading {
    font-weight: 600;
    margin: 0;
}

.headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lastSurvey {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
}

.lastSurveyDate {
    font-weight: 500;
    padding-left: 0.25rem;
}

.repairContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.5rem;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 0.75rem;
    cursor: pointer;
}

.repairContainer {
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

.repairContainer.urgent {
    color: #CC2127;
}

.finishButton {
    background-color: #cc0000;
    color: #fff;
    outline: none;
    border: none;
    padding: 0.625rem 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
}

.repairDoor {
    font-weight: 600;
    width: 20%;
}

.repairIcon {
    width: 10%;
}

.repairMessage {
    width: 70%;
}

.secondaryButton {
    background-color: #333;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1.5rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}

.headingButtonContainer {
    display: flex;
    justify-content: space-between;
}