.background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.26);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
}

.sectionContainer {
    margin-top: 4rem;
    background: #FFFFFF;
    max-width: 490px;
    width: 80%;
    padding: 0.75rem 1.75rem 2rem;
}

.sectionHeading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sectionHeader {
    text-transform: uppercase;
}

.sectionRef {
    font-weight: 600;
}

.sectionMessageContainer {
    margin-top: 1rem;
    padding: 1.75rem;
}

.sectionMessage {
    font-weight: 500;
}

.sectionButtonsContainer {
    margin-top: 2rem;
    padding: 1.75rem;
    display: flex;
    justify-content: space-between;
}

.repairedButton {
    background-color: #d3a045;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
    margin-left: 1rem;
}

.notRepairedButton {
    background-color: #313131;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
    margin-left: 1rem;
}

.repairContainer {
    background-color: #F4F4F4;
    border-radius: 2px;
    padding: 1.75rem;
}

.sectionIcon {
    margin-right: 1rem;
}