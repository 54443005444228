.photoContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    row-gap: 1.5rem;
}

.uploadAllContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.uploadAllButton {
    background-color: #d3a045;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1.5rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}
