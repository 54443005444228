.heading {
    font-weight: 600;
    margin: 0;
}

.headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lastSurvey {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
}

.lastSurveyDate {
    font-weight: 500;
    padding-left: 0.25rem;
}

.repairsContainer {
    margin-top: 2rem;
}

.finishButton {
    background-color: #cc0000;
    color: #fff;
    outline: none;
    border: none;
    padding: 0.625rem 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
}

.secondaryButton {
    background-color: #333;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 0.625rem 1.5rem;
    font-size: 1rem;
    border: 0;
    cursor: pointer;
}

.headingButtonContainer {
    display: flex;
    justify-content: space-between;
}