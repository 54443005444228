.surveyTotalRow {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.surveyTotalRow.total {
    border-top: 1px solid #202020;
    padding-top: 1rem;
}

.surveyTotalContainer {
    margin-top: 2rem;
}

.surveyDoorsContainer p {
    text-align: center;
    font-weight: 500;
}

.surveyResultContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.surveyResultContainer svg {
    height: 192px;
    width: 192px;
    
}

.failed {
    fill: #f56565;
}

.passed {
    fill: #48bb78;
}

.doorContainer {
    display: block;
    border-bottom: 1px solid black;
    padding: 1rem;
}