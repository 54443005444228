.background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.26);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
}

.container {
    margin-top: 4rem;
    background: #FFFFFF;
    max-width: 490px;
    width: 80%;
    padding: 0.75rem 1.75rem 2rem;
}

.repairContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 0.75rem;
    cursor: pointer;
}

.repairDoor {
    font-weight: 600;
    width: 20%;
}

.repairIcon {
    width: 10%;
    font-weight: 500;
}

.repairMessage {
    width: 70%;
    font-weight: 500;
}

.completion {

}

.notes {
    
}