.header {
    background-color: #cc2020;
    color: #fff;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.header__top {
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: space-between;
}

.header__logo {
    object-fit: contain;
    height: auto;
    width: 100%;
    max-width: 131px;
}

.header__topMessage {
    flex: 1 1;
    background-color: #d3a045;
    height: 48.5px;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 1.125rem;
    text-transform: none;
    margin: 0;
    padding-right: 90px;
}

.header__topMenuIcon {
    width: 34px;
    height: 34px;
    margin-left: 1rem;
}

.header__menuIcon {
    border: none;
    background-color: transparent;
    height: 45px;
    width: 45px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 20px;
}

.header__menuIcon.hide {
    display: none;
}

.header__menuIcon img {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 600px) {
    .header__topMessage {
        padding-left: 20px;
        padding-right: 70px;
    }
}